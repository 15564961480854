import React, { useMemo, useState } from 'react';
import { PropTypes } from 'prop-types';
import { Form, Input } from 'semantic-ui-react';
import _ from 'lodash';

const FULL_NAME_ERROR_TEXT = 'Please supply at least 1 given and 1 family name';
const SINGLE_NAME_ERROR_TEXT = 'Please supply a name';

const NameInput = ({
  value, attrName, onChange, isValid, label, onBlur, isFullName, maxLength,
}) => {
  const fieldId = useMemo(() => `${_.kebabCase(attrName)}-input`, [attrName]);
  const relevantError = useMemo(() => (
    isFullName ? FULL_NAME_ERROR_TEXT : SINGLE_NAME_ERROR_TEXT
  ), [isFullName]);

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);

  const handleNameChange = (_e, props) => { onChange(props.value); };

  const handleFocus = () => { setIsFocussed(true); };

  const handleBlur = () => {
    setIsFocussed(false);
    setPreviouslyFocussed(true);
    if (onBlur) onBlur();
  };

  const error = !isFocussed && (previouslyFocussed || !!value) && !isValid && relevantError;

  return (
    <Form.Field
      id={fieldId}
      control={Input}
      type="text"
      autoComplete="name"
      data-testid={fieldId}
      required
      error={error}
      label={label}
      value={value || ''}
      onChange={handleNameChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      maxLength={maxLength}
    />
  );
};

export default NameInput;

NameInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  attrName: PropTypes.oneOf(['grownUpName', 'kidName', 'bankAccountFullName', 'bankAccountFirstName', 'bankAccountLastName']).isRequired,
  isValid: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isFullName: PropTypes.bool,
  maxLength: PropTypes.number,
};

NameInput.defaultProps = {
  value: '',
  onBlur: undefined,
  isFullName: true,
  maxLength: undefined,
};
