import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { Form, Header, Message, Search, Segment } from 'semantic-ui-react';
import AddressAPI from '../../store/AddressAPI';

// This component expects addressProps as an array of objects for each address field/input,
// with each object requiring a value (string), onChange (callback-func) and an optional error.
// It expects 5 fields to cater for 2 'initial' lines of an address, a town/city, county
// and postcode.
// It will call the setWholeAddress callback with the 5 fields.

// The gutterless prop is for situations suiting the placement of the address fields within
// and existing form, and therefore removes any padding immediately around the fields.

// The component relies on the page on which it is rendered containing elements
// with the following ids with data attrs containing the relevant values, in order
// to make requests to the API service:
//
// - 'address_api_base_url'
// - 'address_api_token'

class AutocompleteAddressForm extends Component {
  static propTypes = {
    header: PropTypes.string,
    headerAsLabel: PropTypes.bool,
    addressIsRequired: PropTypes.bool,
    addressProps: PropTypes.array.isRequired,
    onAddressSelect: PropTypes.func.isRequired,
    gutterless: PropTypes.bool,
  }

  static defaultProps = {
    header: 'Address',
    headerAsLabel: false,
    addressIsRequired: false,
    gutterless: false,
  };

  constructor() {
    super();
    this.MIN_SEARCH_CHARS = 4;
    this.FIELD_PLACEHOLDERS = [
      'Address Line 1',
      'Address Line 2',
      'Town or City',
      'County',
      'Postcode',
    ];
    this.state = {
      APIBaseURL: null,
      APIToken: null,
      errorFieldsIndices: [],
      editingManually: false,
      addressSuggestions: [],
      addressFetchError: false,
      fetchingAddress: false,
      // We define and set a key on the Semantic UI search component so that,
      // when a result is selected, we can change the key, which will cause
      // a rerender and subsequent 'blurring' of the field for better UX.
      searchInputKey: 0,
      searchTerm: '',
    };
  }

  componentDidMount() {
    const APIBaseUrlElement = document.getElementById('address_api_base_url');
    const APITokenElement = document.getElementById('address_api_token');
    if (!!APIBaseUrlElement && !!APITokenElement) {
      this.setAPIStateData(APIBaseUrlElement.getAttribute('data'), APITokenElement.getAttribute('data'));
    } else {
      this.enterManualEdit();
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    const searchTermChanged = prevState.searchTerm !== this.state.searchTerm;
    if (searchTermChanged && this.minSearchCharsExist()) this.getSuggestions();
  }

  onErrorReceived = () => {
    this.setState({
      addressFetchError: true, searchTerm: '', addressSuggestions: [],
    });
  }
  onErrorDismiss = () => this.setState({ addressFetchError: false });
  onSearchInput = (_event, { value }) => this.setState({ searchTerm: value });
  setAPIStateData = (APIBaseURL, APIToken) => this.setState({ APIBaseURL, APIToken });
  setFetchingResults = val => this.setState({ fetchingResults: val });
  setFetchingAddress = val => this.setState({ fetchingAddress: val });

  getSuggestions = () => {
    this.setFetchingResults(true);
    AddressAPI.autoComplete(this.state.APIBaseURL, this.state.searchTerm, this.state.APIToken)
      .then(({ data }) => {
        const formattedSuggestions = this.formatSuggestions(data.suggestions);
        this.setState({ addressSuggestions: formattedSuggestions });
      })
      .catch(() => this.onErrorReceived())
      .finally(() => this.setFetchingResults(false));
  };

  getAddressFromId = (id) => {
    this.setFetchingAddress(true);
    AddressAPI.getByID(this.state.APIBaseURL, id, this.state.APIToken)
      .then(({ data }) => this.handleSettingFullAddress(data))
      .catch(() => this.onErrorReceived())
      .finally(() => this.setFetchingAddress(false));
  };

  formatSuggestions = suggestions =>
    suggestions.map(({ id, address }) => ({ id, title: address }));

  enterManualEdit = () => {
    this.setState({ editingManually: true, searchTerm: '', addressFetchError: false });
  };
  exitManualEdit = () => this.setState({ editingManually: false });

  minSearchCharsExist = () => this.state.searchTerm.length >= this.MIN_SEARCH_CHARS;

  handleFieldErrors = (fieldIndex, hasError) => {
    const { errorFieldsIndices } = this.state;
    // if the field has an error and is not in the array, push it in
    if (hasError && !errorFieldsIndices.includes(fieldIndex)) {
      this.setState({ errorFieldsIndices: [...errorFieldsIndices, fieldIndex] });
    // else if the field doesnt have an error and is in the array, remove it
    } else if (!hasError && errorFieldsIndices.includes(fieldIndex)) {
      this.setState({ errorFieldsIndices: _.pull([...errorFieldsIndices], fieldIndex) });
    }
  };

  handleSettingFullAddress = ({
    line_1, line_2, locality, town_or_city, county, postcode,
  }) => {
    let consideredLine2;
    if (line_2) {
      consideredLine2 = line_2;
    } else if (locality) {
      consideredLine2 = locality;
    }
    this.props.onAddressSelect(line_1, consideredLine2, town_or_city, county, postcode);
    if (this.state.editingManually) this.exitManualEdit();
  };

  handleSelectedAddress = (_event, { result }) => {
    this.setState({
      searchTerm: '',
      searchInputKey: this.state.searchInputKey + 1,
      addressSuggestions: [],
    });
    this.getAddressFromId(result.id);
  };

  renderErrorMessage = () => (
    <Message onDismiss={this.onErrorDismiss} size="mini">
      An error has occurred preventing address searching.
      Please dismiss this error and try again, or edit manually.
    </Message>
  );

  renderAddressLineInputs = () => {
    const { addressProps } = this.props;
    const { editingManually, errorFieldsIndices } = this.state;
    return addressProps.map(({ value, onChange, error = false }, i) => {
      this.handleFieldErrors(i, error);
      return (
        <Form.Field key={`address-field${i + 1}`}>
          <Form.Input
            fluid
            value={value || ''}
            onChange={onChange}
            error={editingManually && errorFieldsIndices.includes(i)}
            placeholder={this.FIELD_PLACEHOLDERS[i]}
            disabled={!editingManually}
          />
        </Form.Field>
      );
    });
  };

  renderSearchInput = () => {
    const {
      addressFetchError,
      addressSuggestions,
      editingManually,
      fetchingAddress,
      fetchingResults,
      searchInputKey,
      searchTerm,
      errorFieldsIndices,
    } = this.state;

    const { addressIsRequired, header, headerAsLabel } = this.props;

    // We want to show address errors on the global search field, rather than individual fields,
    // when an error exists, and when NOT editing the fields manually. This is because
    // the fields are disabled and have opacity, so the red state is not clear, plus we
    // want to encourage the users to use the search field to correct address problems.
    const displayGlobalFieldError = errorFieldsIndices.length > 0
      && !editingManually
      && searchTerm.length === 0;

    return (
      <Form.Field className={addressIsRequired ? 'required' : ''} error={displayGlobalFieldError}>
        {headerAsLabel && <label>{header}</label>}
        <Search
          key={searchInputKey}
          onFocus={this.exitManualEdit}
          fluid
          placeholder="Type the address/postcode to search..."
          value={searchTerm}
          onSearchChange={this.onSearchInput}
          disabled={fetchingAddress || addressFetchError}
          minCharacters={this.MIN_SEARCH_CHARS}
          showNoResults={this.minSearchCharsExist()}
          loading={fetchingResults}
          results={addressSuggestions}
          onResultSelect={this.handleSelectedAddress}
          noResultsMessage="No addresses found."
        />
        <p
          onClick={this.enterManualEdit}
          aria-hidden="true"
          className={`false-link${editingManually ? ' disabled' : ''}`}
        >
          <small>Edit Manually</small>
        </p>
      </Form.Field>
    );
  }

  render() {
    const {
      APIBaseURL, APIToken, addressFetchError, fetchingAddress,
    } = this.state;
    const { gutterless } = this.props;

    return (
      <Segment
        id="address-autocomplete"
        basic={gutterless}
        loading={fetchingAddress}
        className={gutterless ? 'no-padding' : ''}
      >
        {!this.props.headerAsLabel && <Header as="h3" content={this.props.header} />}
        {addressFetchError && this.renderErrorMessage()}
        {!!APIBaseURL && !!APIToken && this.renderSearchInput()}
        {this.renderAddressLineInputs()}
      </Segment>
    );
  }
}

export default AutocompleteAddressForm;
