import React from 'react';
import { PropTypes } from 'prop-types';
import { Loader } from 'semantic-ui-react';

const ContainerWithLoader = ({ loading, children }) => (
  <div>
    {loading ? <Loader active content="Loading" inline="centered" /> : children}
  </div>
);

export default ContainerWithLoader;

ContainerWithLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

ContainerWithLoader.defaultProps = {
  children: null,
};
