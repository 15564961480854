import React, { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import { useSignup } from './SignupContext.react';

const SupplementaryKidInputs = () => {
  const {
    kid: {
      kidClassName,
      medicalAndEducationNotes,
      updateKid,
    },
  } = useSignup();

  const handleClassNameChange = useCallback((_e, { value }) => {
    updateKid({ kidClassName: value || null });
  }, []);

  const handleMedicalInfoChange = useCallback((_e, { value }) => {
    updateKid({ medicalAndEducationNotes: value || null });
  }, []);

  return (
    <React.Fragment>
      <Form.Input
        id="kid-class-name-input"
        label="Your child's class name"
        value={kidClassName || ''}
        onChange={handleClassNameChange}
      />
      <Form.TextArea
        id="kid-medical-notes-input"
        label="Medical/educational info we should know about"
        value={medicalAndEducationNotes || ''}
        onChange={handleMedicalInfoChange}
      />
    </React.Fragment>
  );
};

export default SupplementaryKidInputs;
