/* eslint-disable no-use-before-define */
import React, { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Checkbox } from 'semantic-ui-react';
import Instrument from './Instrument';
import { NO_INSTRUMENT_PREFERENCE_VALUE, useSignup } from '../SignupContext.react';

const INSTRUMENT_DESCRIPTIONS = Object.freeze({
  vocals: 'Lead the band from the front, with the most amazing instrument of all.',
  keyboard: 'Bring classical musical technique to the joyous world of your rock and pop band.',
  guitar: 'Give the band a musical edge by playing this iconic rock instrument.',
  drums: 'Keep the band in time, and lead the rhythm and groove of every song.',
});

const InstrumentChoiceCard = ({ instrumentName, type }) => {
  const {
    kid: {
      onWaitlistFor,
      waitlistInstruments,
      updateKid,
      resetKidInstrumentChoices,
      enrollingAndJoiningWaitlist,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const upsold = type === 'selectedChoice' && enrollingAndJoiningWaitlist;
  const waitlistSelected = onWaitlistFor(instrumentName);

  const buttonAtBottom = isMobile && (type === 'upsellOption' || upsold);

  const handleSelectForEnrolment = useCallback(() => {
    // Here, in cases where the user has been 'upsold' to enrol AND join waitlist for preferred
    // instrument (thus a value is occupying index 0 of waitlistInstruments), we want to set
    // their first preference to match the waitlist instrument so the backend can infer the state
    // of play (i.e., enrolled waiting to change).
    updateKid({
      enrolmentInstrument: instrumentName,
      firstPreferenceInstrument: waitlistInstruments[0] || NO_INSTRUMENT_PREFERENCE_VALUE,
    });
  }, [instrumentName, waitlistInstruments]);

  const handleWaitlistToggle = useCallback(() => {
    if (waitlistSelected) {
      updateKid({ waitlistInstruments: _.without(waitlistInstruments, instrumentName) });
    } else {
      updateKid({ waitlistInstruments: [...waitlistInstruments, instrumentName] });
    }
  }, [waitlistInstruments, instrumentName]);

  const checkbox = props => (
    <div className="max-half-width">
      <Checkbox {...{ ...props, onChange: CARD_TYPES[type].onAction }} />
    </div>
  );

  const button = (text, props) => (
    <div className={buttonAtBottom ? 'full-width mt-8' : 'max-half-width'}>
      <Button {...{ ...props, onClick: CARD_TYPES[type].onAction }}>{text}</Button>
    </div>
  );

  const CARD_TYPES = {
    availableOption: {
      onAction: handleSelectForEnrolment,
      selectActionRenderer: () => button('Enrol', { primary: true }),
    },
    waitlistOption: {
      onAction: handleWaitlistToggle,
      selectActionRenderer: () => button('Join waitlist', { basic: true, color: 'blue' }),
    },
    waitlistOnlyOption: {
      onAction: handleWaitlistToggle,
      selectActionRenderer: () => checkbox({ label: 'Join waitlist', checked: waitlistSelected }),
    },
    upsellOption: {
      onAction: handleSelectForEnrolment,
      selectActionRenderer: () => button(
        `Enrol on ${instrumentName} & join ${waitlistInstruments[0]} waitlist`,
        { primary: true, fluid: buttonAtBottom },
      ),
    },
    selectedChoice: {
      onAction: resetKidInstrumentChoices,
      selectActionRenderer: () => button('Change', { basic: true, color: 'blue', fluid: buttonAtBottom }),
      instrumentAltText: waitlistSelected ? 'Join waitlist' : undefined,
    },
  };

  const doubleInstrument = () => (
    <div className="double-instruments flex-row align-center space-between">
      <div>
        <strong>Enrol today on:</strong>
        <Instrument instrumentName={instrumentName} />
      </div>
      <div className="flex-row align-center px-16">
        <strong>+</strong>
      </div>
      <div>
        <strong>Join the waitlist for:</strong>
        <Instrument showAvailability={false} instrumentName={waitlistInstruments[0]} />
      </div>
    </div>
  );

  const singleInstrument = () => (
    <Instrument
      instrumentName={instrumentName}
      altText={CARD_TYPES[type].instrumentAltText}
    />
  );

  return (
    <div className={`instrument-card${type === 'waitlistOnlyOption' && waitlistSelected ? ' blue-border' : ''}`}>
      {type === 'selectedChoice' && (
        <div className="full-width mb-4">
          <strong>Selected:</strong>
        </div>
      )}
      <div className="full-width flex-row align-center space-between">
        <div className={buttonAtBottom && upsold ? 'full-width' : 'max-half-width'}>
          {upsold ? doubleInstrument() : singleInstrument()}
        </div>
        {!buttonAtBottom && CARD_TYPES[type].selectActionRenderer()}
      </div>
      {(type !== 'selectedChoice' || upsold) && (
        <div className="full-width mt-8">
          {!upsold && <div>{INSTRUMENT_DESCRIPTIONS[instrumentName]}</div>}
          {buttonAtBottom && CARD_TYPES[type].selectActionRenderer()}
        </div>
      )}
    </div>
  );
};

export default InstrumentChoiceCard;

InstrumentChoiceCard.propTypes = {
  instrumentName: PropTypes.string.isRequired,
  type: PropTypes.oneOf(
    ['availableOption', 'waitlistOption', 'waitlistOnlyOption', 'upsellOption', 'selectedChoice'],
  ).isRequired,
};
