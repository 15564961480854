const paths = Object.freeze({
  BACKSTAGE: 'https://backstage.rocksteadymusicschool.com',
  SIGNUP_ROOT: '/',
  RESERVE_YOUR_PLACE: '/reserve-your-place',
  COMPLETE_YOUR_APPLICATION: '/complete-your-application',
  SET_UP_DD: '/set-up-direct-debit',
  APPLICATION_COMPLETED: '/application-completed',
  DD_SUMMARY: '/dd-summary',
  ADD_A_KID: '/staff/kids/new',
  NEW_REGISTER: '/staff/registers/new',
  JOBS: '/staff/jobs',
  SETTINGS: '/users/edit',
  LOGIN_ROOT: '/users/sign_in',
  STAFF_ROOT: '/staff/',
  OVERDUE_REGISTERS_REPORT_PATH: '/staff/reports/overdue_registers',
  POSSIBLE_DUPLICATE_KIDS_REPORT_PATH: '/staff/reports/kid_duplicate',
  KIDS_IN_BANDS_ON_OWN_REPORT_PATH: '/staff/reports/kids_in_bands_on_own',
  KIDS_THAT_HAVE_LEFT_THE_SCHOOL_FOREVER_REPORT_PATH: '/staff/reports/kids_that_have_left_the_school_forever',
  REGISTERS_WITH_NOTES_REPORT_PATH: '/staff/reports/registers_with_notes',
  DUPLICATE_GROWN_UPS_REPORT_PATH: '/staff/reports/duplicate_grown_ups',
  POSSIBLE_OPPORTUNITIES_TO_EXTEND_REPORT_PATH: '/staff/reports/timetables_opportunities_to_extend',
  CLOSED_TO_NEW_SIGNUPS_REPORT_PATH: '/staff/reports/timetables_closed_to_signups',
  GROWN_UPS_INDEX: '/staff/grown_ups/',
  SEARCH_KIDS_BY_EMAIL:
    '/staff/kids?q%5Bgrown_up_name_or_grown_up_email_or_grown_up_phone_or_grown_up_dd_email_address_or_grown_up_alternative_contact_cont%5D=',
  SEARCH_JOBS_BY_NAME: '/staff/jobs?q%5Bname_or_user_name_cont%5D=',
  TIMETABLE: id => `/staff/timetables/${id}/edit`,
  GROWNUP: id => `/staff/grown_ups/${id}/edit`,
  SCHOOL: id => `/staff/schools/${id}`,
  TRANSFER_SCHOOL_DATA: id => `/staff/schools/${id}/transfer`,
  SCHOOLS: '/staff/schools',
  ADD_A_TIMETABLE: urn => `/staff/timetables/new?urn=${urn}`,
  SALES_TRACKER: id => `/staff/sales_trackers/${id}`,
  ACTIVE_TIMETABLES_PATH: '/staff/timetables?q%5Bstarted%5D=1',
  FUTURE_TIMETABLES_PATH: '/staff/timetables?q%5Bfuture%5D=1',
  GAPS_PATH: '/staff/jobs?q[has_gaps]=1',
  ASSEMBLIES_BOOKED_PATH: '/staff/reports/assemblies_future',
  WORKSHOPS_BOOKED_PATH: '/staff/reports/workshops_future',
  CANCELLING_SCHOOLS_PATH: '/staff/timetables?q%5Bcancelling%5D=1',
  EDIT_JOB: id => `/staff/jobs/${id}/edit`,
  WWW_TERMS_CONDITIONS: 'https://www.rocksteadymusicschool.com/terms-and-conditions-parents',
  WWW_PRIVACY_POLICY: 'https://www.rocksteadymusicschool.com/privacy-policy',
  TIMETABLE_PARENT_COMMS_PATH: timetable_id => `/staff/send_comms/timetable/${timetable_id}?kid_ids=`,
  EDIT_BUSINESS_KPI: kpi_id => `/staff/business_kpis/${kpi_id}/edit`,
  MANAGE_BUSINESS_KPI_DATA: kpi_id => `/staff/business_kpis/${kpi_id}`,
  DOWNLOAD_BUSINESS_KPI_DATA: (kpi_id, year_beginning) => `/staff/business_kpis/${kpi_id}/csv_data?academic_year_beginning=${year_beginning}`,
  SCHOOLS_BY_TRUST_NAME: (trust_name, exclude_secondary) =>
    `/staff/schools?q[trusts_name_eq]=${trust_name}${exclude_secondary && '&q[excluding_secondary_schools]=1'}`,

  // SOCIALS
  FACEBOOK: 'https://www.facebook.com/rocksteadymusicschool',
  TWITTER: 'https://twitter.com/rsmusicschool',
  INSTAGRAM: 'https://www.instagram.com/rocksteadymusicschool',
  YOUTUBE: 'https://www.youtube.com/user/RSMusicSchool',

  MAPS_SEARCH: 'https://www.google.co.uk/maps/search/',
});

export default paths;
