import React, { useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';

const FIELD_ID = 'payment-receipts-email';
const INVALID_EMAIL_TEXT = 'Please supply a valid email address';

const PaymentReceiptEmailInput = () => {
  const {
    grownUp: {
      paymentReceiptEmail,
      paymentReceiptEmailValid,
      trimAndCleanPaymentReceiptEmail,
      updateGrownUp,
    },
  } = useSignup();

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);

  const handleChange = (_e, { value }) => { updateGrownUp({ email: value || null }); };

  const handleFocus = () => { setIsFocussed(true); };

  const handleBlur = () => {
    trimAndCleanPaymentReceiptEmail();
    setPreviouslyFocussed(true);
    setIsFocussed(false);
  };

  const error = (
    !isFocussed
      && (previouslyFocussed || !!paymentReceiptEmailValid)
      && (!paymentReceiptEmailValid && INVALID_EMAIL_TEXT)
  );

  return (
    <Form.Field
      id={FIELD_ID}
      label="Email address for receipts"
      required
      data-testid={FIELD_ID}
      type="email"
      autoComplete="email"
      error={error}
      value={paymentReceiptEmail || ''}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      control={Input}
    />
  );
};

export default PaymentReceiptEmailInput;
