import React from 'react';
import { Grid, Image, Button, Segment } from 'semantic-ui-react';
import DDImage from 'images/dd_logo_small_transparent.png';
import moment from 'moment';
import WindowUtils from '../../Utils/WindowUtils';
import { useSignup } from '../SignupContext.react';
import BackstageLinkSection from './BackstageLinkSection';
import DirectDebitGuaranteeList from '../DirectDebitGuaranteeList';

const DirectDebitSummary = () => {
  const {
    grownUp: {
      bankAccountNumber,
      bankSortCode,
      bankAddress,
      bacsReference,
      bankAccountFullName,
      phoneNumber,
      paymentReceiptEmail,
      firstPaymentDate,
    },
  } = useSignup();

  const headerSection = () => (
    <div>
      <p>
        Thank you for booking your child onto Rocksteady lessons. You will soon receive an email
        from us with all the information you need to get them started. <br />
        <br /> If you don&lsquo;t see this email, please check your spam/junk folder and add
        info@rocksteadymusicschool.com to your email contacts.
      </p>
      <p>
        If you have any questions, please call us on 0330 113 0330.
      </p>
      <br />
    </div>
  );

  const aboutSection = () => (
    <div>
      <h3>About your Direct Debit</h3>
      <p>Your Direct Debit Instruction set up is complete.</p>
      <p>
        Confirmation of the instruction will be sent to you within 3 working days,
        or received by you no later than ten working days before the first collection
        or by letter or by email if you have requested to use this method.
      </p>
      <p>
        Thank you very much for your Direct Debit instruction details.
        Here is the Direct Debit Guarantee for your information.
      </p>
      <br />
    </div>
  );

  const bankDetails = () => (
    <div>
      <h4>Bank / Building Society Account Number</h4>
      <span>{bankAccountNumber}</span>
      <h4>Branch Sort Code</h4>
      <span>{bankSortCode}</span>
      <h4>Name and Address of bank or building society</h4>
      <span>{bankAddress}</span>
      <h4>BACS Reference</h4>
      <span>{bacsReference}</span>
    </div>
  );

  const guDetails = () => (
    <div>
      <h4>Name of Account Holder</h4>
      <p>{bankAccountFullName}</p>
      <h4>Phone Number</h4>
      <span>{phoneNumber}</span>
      <h4>Email Address for payment receipts</h4>
      <span>{paymentReceiptEmail}</span>
      <br />
      <br />
    </div>
  );

  const rsAddress = () => (
    <div>
      <h4>Rocksteady Music School</h4>
      <p>
        Bohunt Manor<br />
        Portsmouth Road<br />
        Liphook<br />
        GU30 7DL
      </p>
      <br />
      <br />
    </div>
  );

  const instruction = () => (
    <Segment>
      <h4>Instruction to your bank or building society</h4>
      <p>
        Please pay PSL re Rocksteady Music School Direct
        Debits from the account detailed in this
        Instruction subject to the safeguards assured by the Direct Debit Guarantee.
        I understand that this Instruction may remain with PSL re Rocksteady
        Music School and, if so, details will be passed electronically
        to my Bank / Building Society.
      </p>
    </Segment>
  );

  const guarantee = () => (
    <Segment>
      <h2>
        The Direct Debit Guarantee
        <Image src={DDImage} size="tiny" floated="right" />
      </h2>
      <DirectDebitGuaranteeList />
    </Segment>
  );

  const dateOrTBC = () => {
    if (!firstPaymentDate) return 'TBC';
    return moment(firstPaymentDate).format('Do MMM YYYY');
  };

  const termsAndConditions = () => (
    <div>
      <h4>Terms and Conditions</h4>
      <p>
        <b>
          Bank details are saved to Smart Debit&lsquo;s database.
          Smart Debit collects payments on Rocksteady Music School&lsquo;s behalf.
        </b>
      </p>
      <p>
        The first collection will come out of your account
        on <b>{dateOrTBC()}</b> and the same day
        each month (depending on the frequency agreed) thereafter. If there are any changes
        to the date, amount or frequency of your Direct Debit, we will always give you 3 working
        days notice in advance of your account being debited.
      </p>
      <p>
        The company that will appear on your bank statement against
        the Direct Debit will be <b>{JSON.stringify('PSL re Rocksteady Music School')}</b>.
      </p>
    </div>
  );

  const printBtn = () => (
    <div>
      <br />
      <p>
        <Button
          size="large"
          className="offline-dd-button"
          floated="left"
          onClick={() => WindowUtils.print('printArea')}
        >
          Print Direct Debit Instruction
        </Button>
      </p>
      <br />
    </div>
  );

  return (
    <div>
      <br />
      {headerSection()}
      <div id="printArea">
        {aboutSection()}
        <hr style={{ borderTopStyle: 'dashed', borderTopWidth: 2 }} />
        <br />
        <Grid columns={2} stackable>
          <Grid.Column width={8}>
            {rsAddress()}
            {bankDetails()}
          </Grid.Column>
          <Grid.Column width={8}>
            {guDetails()}
            {instruction()}
          </Grid.Column>
        </Grid>
        <br />
        <hr style={{ borderTopStyle: 'dashed', borderTopWidth: 2 }} />
        {guarantee()}
        {termsAndConditions()}
      </div>
      {printBtn()}
      <br />
      <BackstageLinkSection />
    </div>
  );
};

export default DirectDebitSummary;
