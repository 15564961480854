import React, { useCallback } from 'react';
import { Form, Icon, Popup } from 'semantic-ui-react';
import { useSignup } from './SignupContext.react';

const YES_LABEL = 'Yes, I consent for my child to be recorded and their performance made available for the school and parents of other children in their band';
const NO_LABEL = 'No, I do not want the child I am signing up included in recorded performances';

const RecordingConsentRadioButtons = () => {
  const {
    kid: { concertVideoPermission, updateKid },
  } = useSignup();

  const handleChange = useCallback((_e, { value }) => {
    updateKid({ concertVideoPermission: value === 'yes' });
  }, []);

  const popupContent = () => (
    <em>
      On occasion, Rocksteady showcases the performances of its Band Members (
      the child you are signing up
      ) by offering the opportunity for you to see videos of your child playing their instrument.
      <br />
      <br />
      The recorded performances are made available to the school and other Rocksteady parents in
      {' '}
      your child&apos;s band via our secure &apos;Backstage&apos; login.
      {' '}
      We shall only use these recordings for the purposes identified above.
      {' '}
      For more information, please see our Privacy Notice.
    </em>
  );

  const popupTrigger = () => (
    <Icon size="small" name="info" circular />
  );

  return (
    <div className="field required">
      <label>
        Recorded performances
        <span>{' '}
          <Popup position="bottom center" wide content={popupContent()} trigger={popupTrigger()} />
        </span>
      </label>
      <Form.Radio
        label={YES_LABEL}
        value="yes"
        checked={concertVideoPermission === true}
        onChange={handleChange}
      />
      <Form.Radio
        label={NO_LABEL}
        value="no"
        checked={concertVideoPermission === false}
        onChange={handleChange}
      />
    </div>
  );
};

export default RecordingConsentRadioButtons;
