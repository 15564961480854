import React, { useCallback, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import OFFERED_INSTRUMENTS from '../../../consts/instruments';
import StringUtils from '../../Utils/StringUtils';

import { NO_INSTRUMENT_PREFERENCE_VALUE, useSignup } from '../SignupContext.react';

const InstrumentPreferences = () => {
  const {
    kid: {
      capitalizedFirstName,
      waitlistInstruments,
      firstPreferenceInstrument,
      firstPreferenceSet,
      secondPreferenceInstrument,
      offerSecondPreference,
      updateKid,
    },
  } = useSignup();

  const formattedNoPreferenceString = useMemo(() => (
    StringUtils.humanize(NO_INSTRUMENT_PREFERENCE_VALUE)
  ), []);
  const FORMATTED_INSTRUMENT_STRINGS = useMemo(() => (
    OFFERED_INSTRUMENTS.reduce(
      (obj, instrumentName) => ({ ...obj, [instrumentName]: StringUtils.humanize(instrumentName) }),
      {},
    )
  ), []);

  const NO_PREFERENCE_CHOICE = Object.freeze(
    { value: NO_INSTRUMENT_PREFERENCE_VALUE, text: formattedNoPreferenceString },
  );
  const noPreferenceChoice = key => ({ ...NO_PREFERENCE_CHOICE, key });

  const firstPreferenceOptions = useCallback(() => ([
    noPreferenceChoice('first-pref-0'),
    ...waitlistInstruments
      .map((instrument, i) => ({ key: `first-pref-${i + 1}`, value: instrument, text: FORMATTED_INSTRUMENT_STRINGS[instrument] })),
  ]), [waitlistInstruments]);

  const secondPreferenceOptions = useCallback(() => ([
    noPreferenceChoice('second-pref-0'),
    ...waitlistInstruments
      .filter(instrument => instrument !== firstPreferenceInstrument)
      .map((instrument, i) => ({ key: `second-pref-${i + 1}`, value: instrument, text: FORMATTED_INSTRUMENT_STRINGS[instrument] })),
  ]), [waitlistInstruments, firstPreferenceInstrument]);

  const setPreferenceInstrument = (prefPrefix, value) => {
    updateKid({ [`${prefPrefix}PreferenceInstrument`]: value });
  };

  const select = (options, value, prefName, disabled) => {
    const handlePreferenceSelect = (_e, { value: selectedValue }) => {
      setPreferenceInstrument(prefName, selectedValue);
    };

    const label = offerSecondPreference ? `${StringUtils.humanize(prefName)} preference` : undefined;

    return (
      <Form.Select
        label={label}
        compact
        options={options}
        onChange={handlePreferenceSelect}
        value={value}
        disabled={disabled}
      />
    );
  };

  const firstPreferenceSelect = () => (
    select(firstPreferenceOptions(), firstPreferenceInstrument, 'first')
  );

  const secondPreferenceSelect = () => (
    select(secondPreferenceOptions(), secondPreferenceInstrument, 'second', !firstPreferenceSet)
  );

  return (
    <Form id="instrument-preferences">
      <h5>Does {capitalizedFirstName || 'your child'} have a preferred instrument?</h5>
      <Form.Group widths="equal">
        {firstPreferenceSelect()}
        {offerSecondPreference && secondPreferenceSelect()}
      </Form.Group>
    </Form>
  );
};

export default InstrumentPreferences;
