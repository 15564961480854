import React, { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import { useSignup } from './SignupContext.react';

const YES_LABEL = 'Yes, please keep me informed about Rocksteady products such as holiday camps and events';
const NO_LABEL = 'No, please do not let me know about Rocksteady products such as holiday camps and events';

const MarketingRadioButtons = () => {
  const {
    grownUp: { commsMarketing, updateGrownUp },
  } = useSignup();

  const handleChange = useCallback((_e, { value }) => {
    updateGrownUp({ commsMarketing: value });
  }, []);

  return (
    <div className="field required">
      <label>Can we keep in touch?</label>
      <Form.Radio
        label={YES_LABEL}
        value="yes"
        checked={commsMarketing === 'yes'}
        onChange={handleChange}
      />
      <Form.Radio
        label={NO_LABEL}
        value="no"
        checked={commsMarketing === 'no'}
        onChange={handleChange}
      />
    </div>
  );
};

export default MarketingRadioButtons;
