import React, { useCallback } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from './SignupContext.react';

const FIELD_ID = 'grown-up-alt-contact';

const AltContactInput = () => {
  const {
    grownUp: { alternativeContact, updateGrownUp },
  } = useSignup();

  const handleChange = useCallback((_e, { value }) => {
    updateGrownUp({ alternativeContact: value || null });
  }, []);

  const handleBlur = useCallback(() => {
    if (alternativeContact) {
      updateGrownUp({ alternativeContact: alternativeContact.trim() });
    }
  }, [alternativeContact]);

  return (
    <Form.Field
      id={FIELD_ID}
      label="Alternative contact details"
      placeholder="e.g., Gran (Mary): 07777111222"
      data-testid={FIELD_ID}
      value={alternativeContact || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      control={Input}
    />
  );
};

export default AltContactInput;
