import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Message } from 'semantic-ui-react';
import { useSignup } from './SignupContext.react';
import StepOne from './StepOne/index';
import StepTwo from './StepTwo/index';
import DateUtils from '../Utils/DateUtils';
import WindowUtils from '../Utils/WindowUtils';
import Success from './Success/index';
import StepThree from './StepThree';
import StepFour from './StepFour';

const DECEMBER_MONTH_INDEX = 11;

// These step numbers are safe to go back to mid-signup
const GO_BACKABLE_STEP_NUMS = Object.freeze([1, 3]);

const SignupForm = () => {
  const {
    kid: { enrolmentInstrument, waitlistInstruments },
    page: {
      httpErrorsExist,
      actionSuccessful,
      resetHttpErrors,
      httpErrors,
    },
  } = useSignup();

  // Temporary manual history management solution to remove dependency from old version
  // of react-router-dom we currently depend on (without useHistory hook).
  // This implementation will safely allow users to press back/forward buttons around the
  // different steps of the 'form' without losing previously inputted data.
  const { history } = window;
  const { state: historyState, pushState, replaceState } = history;
  const historyStateStepNum = historyState && historyState.stepNum;

  const [stepNum, setStepNum] = useState(1);

  const isDecember = useMemo(() => DateUtils.today().getMonth() === DECEMBER_MONTH_INDEX, []);
  const [displayChristmasMessage, setDisplayChristmasMessage] = useState(isDecember);
  const handeRemoveChristmasMessage = useCallback(() => setDisplayChristmasMessage(false), []);

  useEffect(() => {
    const setStepNumFromHistory = ({ state }) => {
      setStepNum(state.stepNum);
    };

    replaceState({ stepNum });

    window.addEventListener('popstate', setStepNumFromHistory);

    return () => {
      window.removeEventListener('popstate', setStepNumFromHistory);
    };
  }, []);

  const safeToGoBackTo = useCallback(step => GO_BACKABLE_STEP_NUMS.includes(step), []);

  // This handles the management of browser history state
  useEffect(() => {
    resetHttpErrors();

    if (stepNum > historyStateStepNum && safeToGoBackTo(historyStateStepNum)) {
      pushState({ stepNum });
    } else if (stepNum < historyStateStepNum || !safeToGoBackTo(historyStateStepNum)) {
      replaceState({ stepNum });
    }

    WindowUtils.scrollToTop();
  }, [stepNum, safeToGoBackTo, resetHttpErrors]);

  useEffect(() => {
    if (httpErrorsExist) WindowUtils.scrollToTop();
  }, [httpErrorsExist]);

  const handleStepComplete = useCallback(() => {
    setStepNum(prev => prev + 1);
  }, []);

  // The number of steps passed through depends on the action being
  // carried out by the user.
  // Joining the waitlist will just take you to end of step2.
  // If instead a place has been reserved on step2, step3 handles gathering
  // of additional info, as well as some direct debit qualifiers.
  // If the provided direct debit qualifier answers mean enrolment isnt possible
  // at this time, a chance to join waitlist is instead offered.
  // If the provided direct debit qualifier answers mean enrolment IS possible,
  // step4 handles the final gathering of the bank account info before enrol commit.
  const applicationSteps = useMemo(() => ([
    () => <Success />,
    () => <StepOne onStepComplete={handleStepComplete} />,
    () => <StepTwo onStepComplete={handleStepComplete} />,
    () => <StepThree onStepComplete={handleStepComplete} />,
    () => <StepFour />,
  ]), [handleStepComplete]);

  const VALID_STEP_NUMS = useMemo(() => (
    applicationSteps.map((_el, i) => i)
  ), [applicationSteps.length]);

  // This effectively locks the user to the success screen upon finish. If in strange case they
  // want to try start again, they would have to refresh (which would reset the state).
  useEffect(() => {
    if (actionSuccessful && stepNum > 0) setStepNum(0);
  }, [actionSuccessful, stepNum]);

  const noInstrumentsChosen = useMemo(() => (
    !enrolmentInstrument && !waitlistInstruments.length
  ), [enrolmentInstrument, waitlistInstruments.length]);

  // This effectively locks the user to the first step if they dont have an instrument(s) chosen.
  // This will likely happen if the user has gone back and changed instruments
  useEffect(() => {
    if (noInstrumentsChosen && stepNum > 1) setStepNum(1);
  }, [noInstrumentsChosen, stepNum]);

  const preChristmasMessage = () => (
    <div className="page-section background-white">
      <Message info onDismiss={handeRemoveChristmasMessage}>
        <Message.Header>Good news for Christmas time!</Message.Header>
        <Message.Content>No payments until the New Year if you enrol today</Message.Content>
      </Message>
    </div>
  );

  const errorMessage = () => (
    <div className="page-section background-white">
      <Message error onDismiss={resetHttpErrors}>
        <Message.Header>Something went wrong!</Message.Header>
        <Message.List>
          {httpErrors.map((error, i) => <Message.Item key={`error-${i + 1}`}>{error}</Message.Item>)}
        </Message.List>
        <Message.Content>
          Please try again. If the problem persists, please contact us.
        </Message.Content>
      </Message>
    </div>
  );

  return (
    <React.Fragment>
      {httpErrorsExist && errorMessage()}
      {displayChristmasMessage && preChristmasMessage()}
      <div className="page-section background-white">
        {VALID_STEP_NUMS.includes(stepNum) && applicationSteps[stepNum]()}
      </div>
    </React.Fragment>
  );
};

export default SignupForm;
