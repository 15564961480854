import React, { useCallback } from 'react';
import { Form, Grid, Image } from 'semantic-ui-react';
import DDImage from 'images/dd_logo_small_transparent.png';
import { useMediaQuery } from 'react-responsive';
import { useSignup } from '../SignupContext.react';
import YourBookingSoFar from '../YourBookingSoFar';
import NameInput from '../NameInput';
import StringUtils from '../../Utils/StringUtils';
import PaymentReceiptEmailInput from './PaymentReceiptEmailInput';
import BankAccountNumberInput from './BankAccountNumberInput';
import BankSortCodeInput from './BankSortCodeInput';
import DirectDebitGuaranteeModal from './DirectDebitGuaranteeModal';

export const MAX_FULL_NAME_LENGTH = 18;

const BankDetailsFields = () => {
  const {
    grownUp: {
      bankAccountFullName,
      bankAccountFullNameValid,
      bankAccountFirstName,
      bankAccountLastName,
      bankAccountNumberValid,
      bankSortCodeValid,
      paymentReceiptEmailValid,
      updateGrownUp,
    },
  } = useSignup();

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const requiredDataCollected = !!(
    bankAccountFullNameValid
      && bankAccountFirstName
      && bankAccountLastName
      && bankAccountNumberValid
      && bankSortCodeValid
      && paymentReceiptEmailValid
  );

  const handleFullNameChange = useCallback((value) => {
    updateGrownUp({ bankAccountFullName: value || null });
  }, []);

  const handleFullNameBlur = useCallback(() => {
    updateGrownUp({ bankAccountFullName: StringUtils.trimAndCleanFullName(bankAccountFullName) });
  }, [bankAccountFullName]);

  const handleFirstNameChange = useCallback((value) => {
    updateGrownUp({ bankAccountFirstName: value || null });
  }, []);

  const handleFirstNameBlur = useCallback(() => {
    updateGrownUp({ bankAccountFirstName: StringUtils.trimAndCleanFullName(bankAccountFirstName) });
  }, [bankAccountFirstName]);

  const handleLastNameChange = useCallback((value) => {
    updateGrownUp({ bankAccountLastName: value || null });
  }, []);

  const handleLastNameBlur = useCallback(() => {
    updateGrownUp({ bankAccountLastName: StringUtils.trimAndCleanFullName(bankAccountLastName) });
  }, [bankAccountLastName]);

  return (
    <Grid stackable columns={3}>
      <Grid.Column mobile={16} tablet={16} computer={6}>
        <YourBookingSoFar />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={16} computer={10}>
        <div className="flex-row align-center justify-between mb-16">
          <Image src={DDImage} size="small" />
          <DirectDebitGuaranteeModal />
        </div>
        <NameInput
          value={bankAccountFullName}
          isValid={bankAccountFullNameValid}
          onBlur={handleFullNameBlur}
          attrName="bankAccountFullName"
          maxLength={MAX_FULL_NAME_LENGTH}
          label={`Full name of account holder (Maximum ${MAX_FULL_NAME_LENGTH} characters)`}
          onChange={handleFullNameChange}
        />
        <NameInput
          value={bankAccountFirstName}
          isValid={!!bankAccountFirstName}
          onBlur={handleFirstNameBlur}
          attrName="bankAccountFirstName"
          label="First name"
          onChange={handleFirstNameChange}
          isFullName={false}
        />
        <NameInput
          value={bankAccountLastName}
          isValid={!!bankAccountLastName}
          onBlur={handleLastNameBlur}
          attrName="bankAccountLastName"
          label="Last name"
          onChange={handleLastNameChange}
          isFullName={false}
        />
        <PaymentReceiptEmailInput />
        <BankAccountNumberInput />
        <BankSortCodeInput />
        <Form.Button
          fluid={isMobile}
          floated="right"
          disabled={!requiredDataCollected}
          primary
          content="Validate bank details and enrol"
        />
      </Grid.Column>
    </Grid>
  );
};

export default BankDetailsFields;
