import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Message, Search } from 'semantic-ui-react';
import PublicAPI from '../../../store/PublicAPI';
import DateUtils from '../../Utils/DateUtils';
import { useSignup } from '../SignupContext.react';

const MIN_SEARCH_QUERY_LENGTH = 3;

const TimetableSelect = () => {
  const {
    timetable: {
      timetableId,
      timetableName,
      updateTimetable,
      lessonsAreOn,
      regularMonthlyCost,
      expectedNextTTPrice,
      resetTimetable,
    },
  } = useSignup();

  const [searchQuery, setSearchQuery] = useState('');
  const [queryPending, setQueryPending] = useState(false);
  const [results, setResults] = useState([]);

  const formatResults = (data) => {
    setResults(data.map(({ id, name }) => ({ id, title: name })));
  };

  const search = useCallback((query) => {
    setQueryPending(true);
    PublicAPI.searchForTimetable(query)
      .then(({ data }) => { formatResults(data); })
      .finally(() => setQueryPending(false));
  }, []);

  useEffect(() => {
    if (timetableName !== searchQuery) {
      if (searchQuery.length >= MIN_SEARCH_QUERY_LENGTH) {
        search(searchQuery);
      } else if (results.length) {
        setResults([]);
        resetTimetable();
      }
    }
  }, [searchQuery, search, resetTimetable]);

  useEffect(() => {
    if (timetableName) {
      setSearchQuery(timetableName);
    }
  }, [timetableName]);

  const handleSearchChange = useCallback((_e, { value }) => {
    setSearchQuery(value);
  }, []);

  const handleResultSelect = useCallback((_e, { result }) => {
    updateTimetable({ timetableId: result.id, timetableName: result.title });
  }, []);


  // Only need to show price change disclaimer if is now between apr and aug
  const betweenAprAndAug = useMemo(() => DateUtils.dateIsBetweenMonths(4, 8), []);

  const priceChangeDisclaimer = () => (
    <p style={{ fontSize: '0.8em', fontWeight: 'bolder' }}>
      <strong>
        Prices are subject to change on 1st September, in line with our T&Cs.
      </strong>
    </p>
  );

  const regularIsBelowExpected = () => {
    const regularFloat = parseFloat(regularMonthlyCost?.replace('£', ''));
    const expectedFloat = parseFloat(expectedNextTTPrice?.replace('£', ''));
    return regularFloat < expectedFloat;
  };

  const monthlyCost = (price) => {
    if (price === '£0.00') {
      return 'The timetable price is currently paused.';
    }
    return (
      <React.Fragment>
        The regular monthly cost is <strong>{price}</strong>.
      </React.Fragment>
    );
  };

  const selectedTimetableInfo = () => (
    <Message>
      <p>
        Lessons at {timetableName} happen on <strong>{lessonsAreOn}</strong>.{' '}
        {monthlyCost(regularMonthlyCost)}
      </p>
      <p>
        <strong>Risk free</strong>: If you cancel within the first month you won&rsquo;t be
        charged anything, you can also cancel at any point after this, just let us know by the
        20th of any month and your payments will be stopped.
      </p>
      {betweenAprAndAug && regularIsBelowExpected() && priceChangeDisclaimer()}
    </Message>
  );

  return (
    <div className="field">
      <label>Find your child&rsquo;s school</label>
      <Search
        fluid
        loading={queryPending}
        placeholder="Search school here"
        onResultSelect={handleResultSelect}
        onSearchChange={handleSearchChange}
        results={results}
        value={searchQuery}
        noResultsMessage="No timetables found"
        showNoResults={!queryPending}
        minCharacters={MIN_SEARCH_QUERY_LENGTH}
      />
      {timetableId && selectedTimetableInfo()}
    </div>
  );
};

export default TimetableSelect;
