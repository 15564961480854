import HTTPWrapper from '../utilities/HTTPWrapper';

// Currently the service we are using is 'getAddress()', https://documentation.getaddress.io/
// The tokens required are set per url making requests to their service.
// In our instance we have just added the host 'rocksteadymusicschool.com' which caters
// for all sub-domains too.

// If utilisation/making requests to the service is required for development purposes, it
// is recommended to first make use of a personal account (free tier offers 20 requests per-hour),
// or requesting the actual api key from whoever holds it, and place key in your local .env.

class AddressAPI {
  autoComplete = (baseUrl, term, token) =>
    HTTPWrapper.get(`${baseUrl}/autocomplete/${term}?api-key=${token}&all=true`);

  getByID = (baseUrl, id, token) =>
    HTTPWrapper.get(`${baseUrl}/get/${id}?api-key=${token}`);
}

export default new AddressAPI();
