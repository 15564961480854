import HTTPWrapper from '../utilities/HTTPWrapper';

class PublicAPI {
  reserveAPlace = params => HTTPWrapper.post('/api/enrol/reserve_a_place/', params);

  completePersonalInformation = params => HTTPWrapper.post('/api/enrol/complete_personal_information', params);

  completeApplication = params => HTTPWrapper.post('/api/enrol/complete_application/', params);

  joinWaitingList = params => HTTPWrapper.post('/api/enrol/join_waiting_list/', params);

  getAvailablePlaces = (timetableId, year) => HTTPWrapper.get(`/api/places/timetable/${timetableId}/year/${year}`);

  getTimetablePricingInfo = (timetableId, params) => HTTPWrapper.get(`/api/timetables/${timetableId}/pricing_info/`, params);

  searchForTimetable = query => HTTPWrapper.get(`/api/timetables/find/?q=${query}`);

  checkIfGrownUpExists = params => HTTPWrapper.get('/api/grown_ups/exist.json', params);
}

export default new PublicAPI();
