import React from 'react';
import { Button, Modal, Image } from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive';
import DDImage from 'images/dd_logo_small_transparent.png';
import DirectDebitGuaranteeList from '../DirectDebitGuaranteeList';

const DirectDebitGuaranteeModal = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  return (
    <Modal
      dimmer="inverted"
      closeIcon
      trigger={
        <Button type="button" size="small">
          View Guarantee
        </Button>
      }
    >
      <Modal.Header>
        The Direct Debit Guarantee
        <Image src={DDImage} size="tiny" floated={isMobile ? undefined : 'right'} />
      </Modal.Header>
      <Modal.Content>
        <DirectDebitGuaranteeList />
      </Modal.Content>
    </Modal>
  );
};

export default DirectDebitGuaranteeModal;
