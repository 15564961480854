import React from 'react';
import { Message } from 'semantic-ui-react';

const SomethingWentWrong = () => (
  <Message negative>
    We are really sorry but something has gone wrong (our technical team have been alerted).

    For now, please call the office on 0330 113 0330 and we can help with your enquiry.
  </Message>
);

export default SomethingWentWrong;
