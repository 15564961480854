import React, { useCallback, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';

const FIELD_ID = 'bank-account-number';
const INVALID_TEXT = 'Please supply an 8-digit bank account number';

const BankAccountNumberInput = () => {
  const {
    grownUp: {
      bankAccountNumber,
      bankAccountNumberValid,
      cleanBankAccountNumber,
      updateGrownUp,
    },
  } = useSignup();

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);

  const handleChange = useCallback((_e, { value }) => {
    updateGrownUp({ bankAccountNumber: value || null });
  }, []);

  const handleFocus = () => { setIsFocussed(true); };

  const handleBlur = () => {
    cleanBankAccountNumber();
    setPreviouslyFocussed(true);
    setIsFocussed(false);
  };

  const error = (
    !isFocussed
      && (previouslyFocussed || !!bankAccountNumberValid)
      && (!bankAccountNumberValid && INVALID_TEXT)
  );

  return (
    <Form.Field
      id={FIELD_ID}
      control={Input}
      type="text"
      required
      error={error}
      label="Bank / Building society Account number"
      placeholder="12345678"
      value={bankAccountNumber || ''}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default BankAccountNumberInput;
