import React, { useEffect, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';

const FIELD_ID = 'grown-up-email';
const INVALID_EMAIL_TEXT = 'Please supply a valid email address';
const ACCOUNT_EXISTS_TEXT = 'Account already exists for this address';

const EmailInput = () => {
  const {
    grownUp: {
      email,
      emailValid,
      accountExists,
      updateGrownUp,
      trimAndCleanEmail,
      checkIfAccountExists,
    },
  } = useSignup();

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);
  const [lastCheckedValue, setLastCheckedValue] = useState(null);

  // We want to check if the user already has an account with us if they
  // have provided us with a valid email value.
  // Do this after blur (hence checking the focus), and ONLY if the current
  // email value is different to the value last checked for.
  useEffect(() => {
    if (!isFocussed && emailValid && email !== lastCheckedValue) {
      checkIfAccountExists();
      setLastCheckedValue(email);
    }
  }, [isFocussed, emailValid, email, lastCheckedValue]);

  const handleChange = (_e, { value }) => { updateGrownUp({ email: value || null }); };

  const handleFocus = () => { setIsFocussed(true); };

  const handleBlur = () => {
    trimAndCleanEmail();
    setPreviouslyFocussed(true);
    setIsFocussed(false);
  };

  const error = (
    !isFocussed
      && (previouslyFocussed || !!email)
      && ((!emailValid && INVALID_EMAIL_TEXT) || (accountExists && ACCOUNT_EXISTS_TEXT))
  );

  return (
    <Form.Field
      id={FIELD_ID}
      label="Your email address"
      required
      data-testid={FIELD_ID}
      type="email"
      autoComplete="email"
      error={error}
      value={email || ''}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      control={Input}
    />
  );
};

export default EmailInput;
