import React from 'react';
import { PropTypes } from 'prop-types';
import { Form, Icon, Image, Message, Popup, Segment } from 'semantic-ui-react';
import DDImage from 'images/dd_logo_small_transparent.png';

const DirectDebitRadioButtons = ({
  accountInOwnName,
  onAccountInOwnNameChange,
  onlySignatoryRequired,
  onOnlySignatatoryRequiredChange,
}) => {
  const popupContent = () => (
    <em>
      To complete a Direct Debit Instruction, you will need your account details,
      which can be found, for example, on your bank statement.
    </em>
  );

  const popupTrigger = () => (
    <Icon size="small" name="info" circular />
  );

  const message = () => (
    <Message negative>
      {accountInOwnName === false && (
        <Message.Content>
          You have said that someone else will be paying for the Rocksteady subscription.
          In order to arrange this, please ask the bill payer to contact the office on 0330
          113 0330. We will be happy to set up an account for them.
          You can add your child to the waiting list, this will hold a space for them for 2 weeks,
          while you arrange the Direct Debit.
        </Message.Content>
      )}
      {onlySignatoryRequired === false && (
        <Message.Content>
          You have said that your bank account needs two or more signatures. We are very sorry,
          but you can not use this bank account to set up a Direct Debit with Rocksteady.
          Please select a different bank account, or contact the office on 0330 113 0330
          to discuss other methods of payment.
          You can add your child to the waiting list, this will hold a space for them for 2 weeks,
          while you arrange the Direct Debit.
        </Message.Content>
      )}
    </Message>
  );

  return (
    <Segment>
      <Image src={DDImage} size="tiny" className="mb-16" />
      <div className="field required">
        <label>
          Is the account in your name?
          <span>{' '}
            <Popup position="bottom center" wide content={popupContent()} trigger={popupTrigger()} />
          </span>
        </label>
        <Form.Radio
          label="Yes"
          value="yes"
          checked={accountInOwnName === true}
          onChange={onAccountInOwnNameChange}
        />
        <Form.Radio
          label="No"
          value="no"
          checked={accountInOwnName === false}
          onChange={onAccountInOwnNameChange}
        />
      </div>
      <div className="field required">
        <label>Are you the only signatory required to authorise this Direct Debit?</label>
        <Form.Radio
          label="Yes"
          value="yes"
          checked={onlySignatoryRequired === true}
          onChange={onOnlySignatatoryRequiredChange}
        />
        <Form.Radio
          label="No"
          value="no"
          checked={onlySignatoryRequired === false}
          onChange={onOnlySignatatoryRequiredChange}
        />
      </div>
      {[accountInOwnName, onlySignatoryRequired].includes(false) && message()}
    </Segment>
  );
};

export default DirectDebitRadioButtons;

DirectDebitRadioButtons.propTypes = {
  accountInOwnName: PropTypes.bool,
  onAccountInOwnNameChange: PropTypes.func.isRequired,
  onlySignatoryRequired: PropTypes.bool,
  onOnlySignatatoryRequiredChange: PropTypes.func.isRequired,
};

DirectDebitRadioButtons.defaultProps = {
  accountInOwnName: null,
  onlySignatoryRequired: null,
};

