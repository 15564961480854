export default class WindowUtils {
  static replace = (path) => {
    window.location = path;
  };

  static anyParams = () => window.location.search;

  static print = (id) => {
    const prtContent = document.getElementById(id);
    const WinPrint = window.open();
    WinPrint.document.write('<html><head>');
    WinPrint.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css">');
    WinPrint.document.write('</head><body onload="print();">');
    WinPrint.document.write(prtContent.innerHTML);
    WinPrint.document.write('</body></html>');
    // ensures the CSS is loaded before we try and print
    setTimeout(() => { WinPrint.document.close(); }, 200);
    WinPrint.focus();
  }

  static scrollToTop = () => window.scrollTo(0, 0);

  static alert = message => window.alert(message);

  static scrollToAnchor = id => document.getElementById(id)?.scrollIntoView(true, { behavior: 'smooth' });

  static pushHistory = (url) => {
    window.history.pushState(null, null, url);
  }

  static currentPath = () => window.location.pathname;
}
