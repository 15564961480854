import React, { useCallback, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';

const FIELD_ID = 'bank-sort-code';
const INVALID_TEXT = 'Please supply a 6-digit sort-code';

const BankSortCodeInput = () => {
  const {
    grownUp: {
      bankSortCode,
      bankSortCodeValid,
      cleanSortCode,
      updateGrownUp,
    },
  } = useSignup();

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);

  const handleChange = useCallback((_e, { value }) => {
    updateGrownUp({ bankSortCode: value || null });
  }, []);

  const handleFocus = () => { setIsFocussed(true); };

  const handleBlur = () => {
    cleanSortCode();
    setPreviouslyFocussed(true);
    setIsFocussed(false);
  };

  const error = (
    !isFocussed
      && (previouslyFocussed || !!bankSortCodeValid)
      && (!bankSortCodeValid && INVALID_TEXT)
  );

  return (
    <Form.Field
      id={FIELD_ID}
      control={Input}
      type="text"
      required
      error={error}
      label="Bank / Building society sort-code"
      placeholder="112233"
      value={bankSortCode || ''}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
    />
  );
};

export default BankSortCodeInput;
