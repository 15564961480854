import React from 'react';

const DirectDebitGuaranteeList = () => (
  <ul>
    <li>
      This Guarantee is offered by all banks and building societies that accept instructions
      to pay Direct Debits.
    </li>
    <li>
      If there are any changes to the amount, date or frequency of your Direct Debit PSL re
      Rocksteady Music School will notify you 3 working days in advance of your account
      being debited or as otherwise agreed. If you request PSL re Rocksteady Music School to
      collect a payment, confirmation of the amount and date will be given to you at the
      time of the request.
    </li>
    <li>
      If an error is made in the payment of your Direct Debit, by PSL re Rocksteady Music
      School or your bank or building society you are entitled to a full and immediate
      refund of the amount paid from your bank or building society.<br />
      - If you receive a refund you are not entitled to, you must pay it back when PSL re
      Rocksteady Music School asks you to.
    </li>
    <li>
      You can cancel a Direct Debit at any time by simply contacting your bank or building
      society. Written confirmation may be required. Please also notify us.
    </li>
  </ul>
);

export default DirectDebitGuaranteeList;
