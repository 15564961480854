import React, { useCallback } from 'react';
import AutocompleteAddressForm from '../AutocompleteAddressForm/AutocompleteAddressForm.react';
import { useSignup } from './SignupContext.react';

const ADDRESS_FIELDS = ['addressL1', 'addressL2', 'addressCity', 'addressCounty', 'addressPostcode'];

const AddressInput = () => {
  const { grownUp } = useSignup();

  const setWholeAddress = (
    addressL1, addressL2, addressCity, addressCounty, addressPostcode,
  ) => {
    grownUp.updateGrownUp({
      addressL1,
      addressL2,
      addressCity,
      addressCounty,
      addressPostcode,
    });
  };

  const addressUpcateCallbackFor = field => useCallback((_e, { value }) => {
    grownUp.updateGrownUp({ [field]: value });
  }, []);

  const autocompleteAddressProps = () => ADDRESS_FIELDS.map(field => ({
    value: grownUp[field] || '',
    onChange: addressUpcateCallbackFor(field),
  }));

  return (
    <AutocompleteAddressForm
      addressIsRequired
      headerAsLabel
      gutterless
      addressProps={autocompleteAddressProps()}
      onAddressSelect={setWholeAddress}
    />
  );
};

export default AddressInput;
