// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'signup' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import React from 'react';
import ReactDOM from 'react-dom';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import SomethingWentWrong from '../components/SomethingWentWrong/SomethingWentWrong.react';

// Transitioning over to use of React Context for state management of the signup process.
// Therefore, the signup form will, until full transition, consume from the mobx SignupStore.
import SignupProvider from '../components/SignupForm/SignupContext.react';
import SignupForm from '../components/SignupForm/SignupForm.react';


const bugsnagApiKey = document.getElementById('bugsnag_api_key').getAttribute('data');
const bugsnagClient = bugsnag({
  apiKey: bugsnagApiKey,
});
bugsnagClient.use(bugsnagReact, React);
const ErrorBoundary = bugsnagClient.getPlugin('react');

const loggedIn = document.getElementById('user_logged_in').getAttribute('data') === 'true';
const schoolYearStartedIn = parseInt(document.getElementById('current_school_year').getAttribute('data'), 10);

ReactDOM.render(
  <ErrorBoundary FallbackComponent={SomethingWentWrong}>
    <SignupProvider loggedInStaff={loggedIn} schoolYearStartedIn={schoolYearStartedIn}>
      <SignupForm />
    </SignupProvider>
  </ErrorBoundary>,
  document.getElementById('signup_form_container'),
);
