import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';
import StringUtils from '../../Utils/StringUtils';

import VocalsIcon from '../../../../assets/images/vocals.svg';
import KeyboardIcon from '../../../../assets/images/keyboard.svg';
import GuitarIcon from '../../../../assets/images/guitar.svg';
import DrumsIcon from '../../../../assets/images/drums.svg';

const IMAGES = Object.freeze({
  vocals: VocalsIcon,
  keyboard: KeyboardIcon,
  drums: DrumsIcon,
  guitar: GuitarIcon,
});

const BASS_MESSAGE = 'When you sign up for a guitar space, that could be an electric guitar or a bass guitar depending on availability. The electric guitar and the bass guitar both play an important role in the band, and the teacher will demonstrate this in the first lesson.';

export default function Instrument({ instrumentName, altText }) {
  const {
    timetable: { availablePlacesCountsFor },
  } = useSignup();

  const isGuitar = instrumentName === 'guitar';

  const availabilityStatement = () => {
    let textColor = 'red';
    let statement = 'Full';
    if (availablePlacesCountsFor(instrumentName) === 1) {
      statement = '1 place left';
    } else if (availablePlacesCountsFor(instrumentName) > 1) {
      textColor = 'green';
      statement = (
        <span>
          <small>
            <Icon name="check" />
          </small>
          Available
        </span>
      );
    }
    return <div className={`${textColor}-text`}>{statement}</div>;
  };

  return (
    <div className="flex-row align-center">
      <img
        src={IMAGES[instrumentName]}
        alt={`${instrumentName} icon`}
        width={45}
        height={45}
      />
      <div>
        <strong>
          {StringUtils.humanize(instrumentName)}
          {isGuitar && (
            <span>
              {' '}
              <Popup
                hoverable
                position="bottom center"
                content={BASS_MESSAGE}
                trigger={<Icon color="black" name="info circle" />}
              />
            </span>
          )}
        </strong>
        {altText ? <p>{altText}</p> : availabilityStatement()}
      </div>
    </div>
  );
}

Instrument.propTypes = {
  instrumentName: PropTypes.string.isRequired,
  altText: PropTypes.string,
};

Instrument.defaultProps = {
  altText: null,
};

