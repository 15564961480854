import React, { useCallback, useState } from 'react';
import { Form, Input } from 'semantic-ui-react';
import { useSignup } from '../SignupContext.react';

const FIELD_ID = 'grown-phone-number';
const INVALID_NUMBER_TEXT = 'Please supply a valid phone number';

const PhoneNumberInput = () => {
  const {
    grownUp: { phoneNumber, phoneNumberValid, updateGrownUp },
  } = useSignup();

  const [isFocussed, setIsFocussed] = useState(false);
  const [previouslyFocussed, setPreviouslyFocussed] = useState(false);

  // remove any whitespace
  const trimAndCleanNumber = phoneString => phoneString.replace(/\s+/g, '');

  const handleChange = useCallback((_e, { value }) => {
    updateGrownUp({ phoneNumber: value || null });
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocussed(true);
  }, []);

  const handleBlur = useCallback(() => {
    if (phoneNumber) {
      updateGrownUp({ phoneNumber: trimAndCleanNumber(phoneNumber) });
    }
    if (!previouslyFocussed) setPreviouslyFocussed(true);
    setIsFocussed(false);
  }, [phoneNumber]);

  const error = (
    !isFocussed
      && (previouslyFocussed || !!phoneNumber)
      && !phoneNumberValid
      && INVALID_NUMBER_TEXT
  );

  return (
    <Form.Field
      id={FIELD_ID}
      label="Your phone number"
      required
      data-testid={FIELD_ID}
      type="tel"
      autoComplete="tel"
      error={error}
      value={phoneNumber || ''}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      control={Input}
    />
  );
};

export default PhoneNumberInput;
